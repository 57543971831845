/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Table from "../components/@player-card-templates-table"

const Page = () => {
	const {t} = useTranslation()
	
	return (
		<Layout title={t(`Player Card Templates`)}>
			<Table t={t} />
		</Layout>
	)
}
export default Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["player-card"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
