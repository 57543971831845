/** @jsx jsx */
import * as React from "react"
import { jsx, Flex } from "theme-ui"
import { Link } from "gatsby-plugin-react-i18next"

import Img from "./image"
import ExternalLink from "./external-link"
import usePlayerCard from "../hooks/use-player-card"

type TableProps = {
	t: any
}

const Table = ({t}: TableProps) => {
	const arr = usePlayerCard()
	
	return (<Flex>
		<div sx={{width: `100%`,}}>
			{arr.map((e,i)=>(<React.Fragment key={i}>
				{(!e.vertical && !e.sub) &&(
					<Avatar e={e} t={t} />
				)}
			</React.Fragment>))}
		</div>
		<div sx={{width: `66%`, ml: [3,4], }}>
			{arr.map((e,i)=>(<React.Fragment key={i}>
				{(e.vertical && !e.sub) &&(
					<Avatar e={e} t={t} />
				)}
			</React.Fragment>))}
		</div>
	</Flex>)
}

export default Table

type AvatarProps = {
	e: {
		id: string
		twitter?: string
		tool?: boolean
		link?: string
		source?: string
		vertical?: boolean
	}
	t: any
}

const Avatar = ({e, t}: AvatarProps) => {
	return (<div sx={{
		mb: [3,4],
		border: `solid 3px`,
		borderColor: `border`,
		borderRadius: [`12px`,`16px`],
		overflow: `hidden`,
	}}>
		<Img folder="player-card-sample" name={e.id} />
		<Flex sx={{
			flexDirection: e.vertical ? `column` : `row`,
			textAlign: `center`,
			borderTop: `solid 3px`,
			borderColor: `border`,
			">*": {
				width: `100%`,
				color: `white !important`,
				fontSize: [3,4],
				":nth-of-type(n+2)": {
					borderTop: e.vertical && `solid 3px`,
					borderLeft: !e.vertical && `solid 3px`,
					borderColor: `border`,
				},
			},
		}}>
			{e.tool && (
				<Link to={`/player-card/?t=`+ e.id}>{t(`Tool`)}</Link>
			)}
			{e.link && (
				<Link to={e.link}>{t(`Source`)}</Link>
			)}
			{e.source && (
				<ExternalLink href={e.source}>{t(`Source`)}</ExternalLink>
			)}
		</Flex>
	</div>)
}
